import React, {useEffect, useState} from "react";
import Svg from "react-inlinesvg";
import getResizedImageUrl from "../../../services/getResizedImageUrl";
import * as s from "./index.module.scss";
import OfficeImages from "./OfficeImages";

const SelectedOffice = ({ location = {} }) => {
  const [urlSearchString] = useState(
    typeof window !== "undefined" ? window.location.search : ""
  );

  const floors = location.floors;

  const [selected, setSelected] = useState({});

  useEffect(() => {
    const params = new URLSearchParams(urlSearchString);
    const selectedId = params.get("id");

    for (let i = 0; i < floors.length; i++) {
      const floor = floors[i];
      const { offices, dedicatedDesks } = floor;
      for (let j = 0; j < offices.length; j++) {
        if (offices[j].id === selectedId) {
          setSelected({
            ...offices[j],
            plan_bg: floor.map_background_url,
            plan_svg: floor.map_svg_url,
          });
        }
      }
      for (let k = 0; k < dedicatedDesks.length; k++) {
        if (dedicatedDesks[k].id === selectedId) {
          setSelected({
            ...dedicatedDesks[k],
            plan_bg: floor.map_background_url,
            plan_svg: floor.map_svg_url,
          });
        }
      }
    }
  }, [urlSearchString]);

  const capacity = selected.max_capacity || selected.desks_available || 1;

  if (!selected.id) return null;

  return (
    <div className={`${s.selected_office}`}>
      <h2 className="common-title">Available Workspace</h2>
      <div className="row">
        <div className="col-lg-12 position-relative">
          <div className={s.selected_office_details_box}>
            <p>{selected.price ? "Private Office" : "Dedicated Desk"}</p>
            <p>${selected.price || selected.price_per_desk}/month</p>
            <p>
              {capacity === 1 ? "" : "Up to "}
              {capacity} {capacity === 1 ? "person" : "people"}
            </p>
          </div>
          <OfficeImages office={selected}/>
        </div>
        <div className="col-lg-12">
          <div className={s.wrapper}>
            <img
              src={getResizedImageUrl(selected.plan_bg, 1200, 1200, 1200, 1200)}
              alt=""
              className={s.plan_bg}
            />
            <Svg
              className={s.plan_svg}
              src={`${
                process.env.GATSBY_API_URL
              }/upload/getSvg?url=${encodeURIComponent(selected.plan_svg)}`}
              onLoad={() => {
                const indexedSvgs = {};
                const allSvgElements =
                  document.querySelectorAll("svg path, svg rect");

                for (let index = 0; index < allSvgElements.length; index++) {
                  const element = allSvgElements[index];
                  const svgId = getIdentifierFromSvg(element);
                  indexedSvgs[svgId] = element;
                  element.setAttribute("stroke", "transparent");

                  if (selected && selected.svg_identifier === svgId) {
                    element.setAttribute("fill-opacity", "1");
                    element.setAttribute("fill", "#478261");
                    element.setAttribute("class", s.selected_block);
                  } else {
                    element.setAttribute("fill", "transparent");
                  }
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectedOffice;

const getIdentifierFromSvg = (element) => {
  let whitelistedAttributes = [];
  let identifier = [];
  switch (element.tagName) {
    case "rect":
      whitelistedAttributes = ["width", "x", "y", "height"];
      break;
    case "path":
      whitelistedAttributes = ["d"];
      break;
    default:
      break;
  }

  for (let i = 0; i < element.attributes.length; i++) {
    for (let j = 0; j < whitelistedAttributes.length; j++) {
      if (element.attributes[i].nodeName === whitelistedAttributes[j]) {
        identifier.push(
          whitelistedAttributes[j] + ":" + element.attributes[i].nodeValue
        );
      }
    }
  }

  return identifier.join("|");
};
