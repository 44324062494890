const removeSoName = (name = "") => {
  if (typeof name === "undefined") {
    return "";
  }
  let finalName = name.replace(`${process.env.GATSBY_CLIENT_NAME} `, "");
  finalName = finalName.replace(`${process.env.GATSBY_CLIENT_NAME}`, "");
  if (!finalName) return name;
  return finalName;
};

export default removeSoName;
