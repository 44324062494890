import React from "react";
import * as s from "./index.module.scss";

const ProgressDots = ({ active = false }) => (
  <span className={`${s.progress} ${active ? s.active : ""}`}>
    <span />
    <span />
    <span />
  </span>
);
export default ProgressDots;
