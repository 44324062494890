import React, {useState} from "react";
import ContactHeader from "./ContactHeader";
import * as s from "./index.module.scss";
import request from "../../../services/request";
import {graphql, useStaticQuery} from "gatsby";
import {isEmail} from "validator";

const ContactForm = ({ location = {}, close = () => null }) => {
  const itemsFromBuild = useStaticQuery(graphql`
    query {
      soData {
        soName: name
        soSlug: slug
      }
    }
  `);

  const [progress, setProgress] = useState(false);
  const [formSent, setFormSent] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [notes, setNotes] = useState("");

  const submit = async () => {
    if (
      !name ||
      !email ||
      !isEmail(email) ||
      !phone ||
      !location.monthly_tour_confirmation_recipients_emails
    )
      return;

    setProgress(true);

    const formData = {
      to: location.monthly_tour_confirmation_recipients_emails,
      userEmail: email,
      sp_slug: itemsFromBuild.soData.soSlug,
      replyTo: email,
      emailTitle: `[Form Submission] ${document.title}`,
      emailBodyTitle: "Form details:",
      emailData: [
        {
          label: "Submitted from",
          value: `<a href="${
            typeof window !== "undefined" ? window.location.href : "#"
          }" target="">${location.space_name || document.title}</a>`,
        },
        {
          label: "Full Name",
          value: name,
        },
        {
          label: "Phone Number",
          value: phone,
        },
        {
          label: "Email",
          value: email,
        },
        {
          label: "Location name",
          value: location.space_name,
        },
      ],
      from: `${itemsFromBuild?.soData?.soName || "Worksimply"} <noreply@worksimply.com>`
    };

    await request("handle-form", formData)
      .then(async (res) => {
        // do nothing
      })
      .catch(async (error) => {
        console.log(error);

        // TODO: Notify Error to Slack
      });
    setProgress(false);
    setFormSent(true);
  };
  return (
    <div className={s.form_wrapper}>
      <div
        className={`${s.message_box} ${
          formSent ? s.show_message : s.hide_message
        }`}
      >
        <p>
          <strong>Thank you.</strong>
        </p>
        <p>Someone will reach out to you shortly to discuss next steps.</p>
        <button className="d-lg-none btn btn-primary" onClick={close}>
          Close
        </button>
      </div>
      <div className={formSent ? s.hide_form : ""}>
        <ContactHeader location={location} />
        <div className="mb-3">
          <input
            type="text"
            className="form-control"
            placeholder={"Full Name"}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <input
            type="text"
            className="form-control"
            placeholder={"Work Email"}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <input
            type="text"
            className="form-control"
            placeholder={"Phone Number"}
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <input
            type="text"
            className="form-control"
            placeholder={"Additional Notes"}
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
          />
        </div>
        <div className="text-end mt-4 pt-3">
          <button
            className="btn btn-primary"
            onClick={submit}
            disabled={progress || !name || !email || !isEmail(email) || !phone}
          >
            Get in Touch
          </button>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
