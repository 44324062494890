import axios from "axios";

const BASE_URL = process.env.GATSBY_API_URL || "http://localhost:3000";

const api = (slug, data = {}) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  return axios({
    method: "post",
    url: `${BASE_URL}/${slug}`,
    headers,
    data,
  }).then((r) => r.data);
};

export default api;
