import React from "react";
import * as s from "./index.module.scss";

const Amenities = ({ location = {} }) => {
  return (
    <div className={s.amenities_block}>
      <div className="row">
        <div className="col-sm-12">
          <h2 className={"common-title"}>Amenities</h2>
        </div>
        <div className="col-sm-12">
          <ul>
            {location.high_speed_internet && <li>High Speed Internet</li>}
            {location.meeting_rooms && location.meeting_rooms > 0 ? (
              <li>Access to {location.meeting_rooms} meeting rooms</li>
            ) : null}
            {location.coffee && <li>Coffee &amp; Tea</li>}
            {location.printing && <li>Printing</li>}
            {location.mail_box && <li>Mail box</li>}
            {location.common_areas && <li>Common areas</li>}
            {location.full_kitchen_access && <li>Full kitchen access</li>}
            {location.phone_booths > 0 && (
              <li>Access to {location.phone_booths} phone booths</li>
            )}
            {location.office_access_copy && (
              <li>{location.office_access_copy}</li>
            )}
            {location.pet_friendly && <li>Pet friendly</li>}
            {location.additional_includes &&
              location.additional_includes.map((item, idx) => {
                return <li key={idx}>{item}</li>;
              })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Amenities;
