import React from "react";
import * as s from "./index.module.scss";

const ContactHeader = ({ location = {} }) => {
  return (
    <div className={s.header}>
      <p className={"text-center"}>
        <strong>
          Inquire about
          <br />
          {location.space_name}
        </strong>
      </p>
      <img src={location.user_image_url} alt={location.contact_name} />
      <p className={"text-center"}>
        <strong>{location.contact_name}</strong>
      </p>
    </div>
  );
};

export default ContactHeader;
