const BASE_URL = "https://d331na0x6n3vy8.cloudfront.net/";
const URL_CONSTANT = "https://images.worksimply.ca/";
const SM = 640;
const MD = 960;
const LG = 1440;

const useOptimizedImages = (image, sm, md, lg, xlg) => {
  if (typeof window === "undefined") {
    return image;
  } else if (window.innerWidth <= SM) {
    return imageOptimizer(image, sm);
  } else if (window.innerWidth <= MD) {
    return imageOptimizer(image, md || sm);
  } else if (window.innerWidth <= LG) {
    return imageOptimizer(image, lg || sm);
  } else {
    return imageOptimizer(image, xlg || sm);
  }
};

export default useOptimizedImages;

export const imageOptimizer = (url, width) => {
  if (!url || !width) {
    return;
  }

  const key = url.replace(URL_CONSTANT, "").trim();

  const payload = {
    bucket: "images.worksimply.ca",
    key,
    edits: {
      resize: {
        fit: "cover",
        width,
      },
    },
  };

  const url_slug = require("buffer/")
    .Buffer.from(JSON.stringify(payload))
    .toString("base64");

  return `${BASE_URL}${url_slug}`;
};
