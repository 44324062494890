import React from "react";
import * as s from "./index.module.scss";

const StickyBar = ({ onClick = () => null }) => {
  return (
    <div className={`d-lg-none ${s.wrapper}`}>
      <div className="container py-3">
        <div className="d-flex">
          <button className="btn btn-primary ms-auto" onClick={onClick}>
            Get in Touch
          </button>
        </div>
      </div>
    </div>
  );
};

export default StickyBar;
