import React from "react";
import * as styles from "./index.module.scss";

const Footer = () => (
  <div className={"container"}>
    <footer className={`${styles.footer}`}>
      <div className="row">
        <div className="col-6">
          <p>
            Powered by{" "}
            <a
              href="https://worksimply.com/pro"
              target={"_blank"}
              rel={"noreferrer"}
            >
              Worksimply.com
            </a>
          </p>
        </div>
        <div className="col-6 text-right">
          <ul className={styles.footer_links_home}>
            <li>
              <a
                href={"https://worksimply.com/terms-of-use"}
                target={"_blank"}
                rel={"noreferrer"}
              >
                Terms of Use
              </a>
            </li>
            <li>
              <a
                href={"https://worksimply.com/privacy-policy"}
                target={"_blank"}
                rel={"noreferrer"}
              >
                Privacy Policy
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  </div>
);

export default Footer;
