import axios from "axios";

const request = (endpoint, data = {}) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${process.env.GATSBY_BEARER_TOKEN}`,
  };

  return axios({
    method: "post",
    url: wrapInApiUrl(endpoint),
    headers,
    data,
  });
};

export default request;

const wrapInApiUrl = (slug) => {
  return `${process.env.GATSBY_API_URL}/${slug}`;
};
