import React, {useEffect, useState} from "react";
import Title from "../../Components/Location/Title";
import Gallery from "../../Components/Location/Gallery";
import Amenities from "../../Components/Location/Amenities";
import Map from "../../Components/Location/Map";
import Description from "../../Components/Location/Description";
import SelectedOffice from "../../Components/Location/SelectedOffice";
import Seo from "../../Components/Seo";
import Footer from "../../Components/Location/Footer";
import DesktopContactForm from "../../Components/Location/ContactForm/DesktopContactForm";
import MobileContactForm from "../../Components/Location/ContactForm/MobileContactForm";
import StickyBar from "../../Components/Location/StickyBar";
import api from "../../services/api";
import ProgressDots from "../../Components/ProgressDots";
import isIncompleteProfile from "../../utils/isIncompleteProfile";

const LocationTemplate = ({
  pageContext: { location: buildLocation },
  ...others
}) => {
  const [showMobileContactForm, setShowMobileContactForm] = useState(false);

  const [isValidProfile, setIsValidProfile] = useState(false);

  const [location, setLocation] = useState({});

  useEffect(() => {
    const params = new URLSearchParams(
      typeof window !== "undefined" ? window.location.search : ""
    );
    const selectedId = params.get("id");
    const locationData = {
      id: buildLocation.id,
      selectedOfficeIds: selectedId,
    };

    api("view-sp", locationData)
      .then((res) => {
        if (res.space) {
          setIsValidProfile(!isIncompleteProfile(res.space));
          setLocation(res.space);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);


  if (!location || !location.id)
    return (
      <div className="">
        <Seo title={location.space_name || "Location Profile"} />

        <Title title={location.space_name} noButton />

        <div>
          <ProgressDots active />
        </div>
      </div>
    );

  if (location && location.id && !isValidProfile)
    return (
      <div className="">
        <Seo title={location.space_name || "Location Profile"} />

        <Title title={location.space_name} noButton />

        <div className={"mt-5"}>
          <p className="text-center mt-5">
            The profile is not available.
            <br />
            Please make sure if the URL is correct.
          </p>
        </div>
      </div>
    );

  return (
    <div className="">
      <Seo title={location.space_name || "Location Profile"} />

      {showMobileContactForm ? (
        <MobileContactForm
          location={location}
          close={() => setShowMobileContactForm(false)}
        />
      ) : (
        <>
          <Title title={location.space_name} />
          <Gallery
            images={location.common_images}
            hero_image_url={location.hero_image_url}
          />
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <Description location={location} />
                <SelectedOffice location={location} />
                <Amenities location={location} />
                <Map location={location} />
              </div>
              <div className="col-lg-4 d-none d-lg-block">
                <DesktopContactForm location={location} />
              </div>
            </div>
          </div>

          <Footer />

          <StickyBar onClick={() => setShowMobileContactForm(true)} />
        </>
      )}
    </div>
  );
};

export default LocationTemplate;
