import React, {useState} from "react"
import * as styles from "./index.module.scss"
import LightBox from "react-image-lightbox";
import {imageOptimizer} from "../../../services/getResizedImageUrl";

const OfficeImages = ({office = {}}) => {

    const images = office.images.map(item => item.url)
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);


    if (!office.images || office.images.length === 0) {
        return null;
    }

    return <>
        <button className={`btn btn-primary mb-3 ${styles.view_images_button}`}
                onClick={() => {
                    setIsOpen(true);
                    setPhotoIndex(0);
                }}>View Images</button>
        {isOpen && (
            <LightBox
                enableZoom={false}
                mainSrc={imageOptimizer(images[photoIndex], 1200)}
                nextSrc={images[(photoIndex + 1) % images.length]}
                prevSrc={
                    images[(photoIndex + images.length - 1) % images.length]
                }
                onCloseRequest={() => setIsOpen(false)}
                onMovePrevRequest={() =>
                    setPhotoIndex(
                        (photoIndex + images.length - 1) % images.length
                    )
                }
                onMoveNextRequest={() =>
                    setPhotoIndex((photoIndex + 1) % images.length)
                }
            />
        )}
    </>
}

export default OfficeImages
