import React from "react";
import * as s from "./index.module.scss";
import ContactForm from "../index";

const DesktopContactForm = ({ location = {} }) => {
  return (
    <div className={s.wrapper}>
      <ContactForm location={location} />
    </div>
  );
};

export default DesktopContactForm;
