import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import removeSoName from "../../../utils/removeSoName";
import * as s from "./index.module.scss";
import {get} from "lodash";

const Title = ({ title = "", noButton = false }) => {
  const itemsFromBuild = useStaticQuery(graphql`
    query {
      soData {
        id
        website_url
      }
    }
  `);

  const website_url = get(itemsFromBuild, "soData.website_url", "");

  return (
    <>
      <div className="container">
        <div className={s.header}>
          <img
            src={require("../../../images/logo.png").default}
            alt="Work Nicer Logo"
            className={s.logo}
          />
          {!noButton && website_url && (
            <div className={"ms-auto"}>
              <a
                href={website_url}
                target={"_blank"}
                className={"btn btn-primary"}
              >
                Go to main website
              </a>
            </div>
          )}
        </div>
      </div>
      <div className="container">
        <h1 className={s.title}>{removeSoName(title)}</h1>
      </div>
    </>
  );
};

export default Title;
