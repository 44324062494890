import React from "react";
import nl2br from "../../../utils/nl2br";
import * as styles from "./index.module.scss";

const Description = ({ location = {} }) => {
  return (
    <div className="">
      <h2 className={styles.space_profile_title}>{location.space_title}</h2>
      <p className={"my-0"}>{nl2br(location.space_description)}</p>
    </div>
  );
};

export default Description;
