const isIncompleteProfile = (location = {}) => {
  const {
    monthly_tour_confirmation_recipients_emails,
    user_image_url,
    common_images,
    hero_image_url,
  } = location;

  return (
    !monthly_tour_confirmation_recipients_emails ||
    !user_image_url ||
    !common_images ||
    common_images.length === 0 ||
    !hero_image_url
  );
};

export default isIncompleteProfile;
