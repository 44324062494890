import React, {useState} from "react";
import getResizedImageUrl, {imageOptimizer} from "../../../services/getResizedImageUrl";
import LightBox from "react-image-lightbox";
import ProgressDots from "../../ProgressDots";
import "./index.scss";

const Img = ({ src, ...rest }) => {
  const newSrc = getResizedImageUrl(src, 800, 800, 800, 800);
  return <img src={newSrc} {...rest} alt={""} />;
};

const Gallery = ({ images = [], hero_image_url = null }) => {
  const gallery = [hero_image_url, ...images.map((image) => image.url)];

  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const [loadedImages, setLoadedImages] = useState(0);

  const setLoaded = () => {
    setLoadedImages(loadedImages + 1);
  };

  const isReady = loadedImages >= (gallery.length > 4 ? 5 : 1);

  if (!images || images.length === 0) {
    return null;
  }

  return (
    <div className="container">
      <div
        className={`space-profile-image-grid ${isReady ? "loaded" : "loading"}`}
      >
        <div className="row">
          <div className="col-sm-6 d-flex">
            <div className="image-zoom-container first-image">
              <div
                className="inner"
                onClick={() => {
                  setIsOpen(true);
                  setPhotoIndex(0);
                }}
              >
                <Img
                  src={gallery[0]}
                  alt=""
                  className={"main-image"}
                  onLoad={setLoaded}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="row">
              {gallery.slice(1, 5).map((image, idx) => {
                return (
                  <div className="col-6" key={idx}>
                    <div className={`image-zoom-container secondary-image`}>
                      <div
                        className="inner"
                        onClick={() => {
                          setIsOpen(true);
                          setPhotoIndex(idx + 1);
                        }}
                      >
                        <Img
                          src={image}
                          alt=""
                          className={`img-` + idx}
                          onLoad={setLoaded}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {!isReady && (
            <div>
              <ProgressDots active />
            </div>
          )}

          <div className={`col-sm-12 all-button`}>
            <button
              className="btn btn-primary"
              onClick={() => {
                setIsOpen(true);
                setPhotoIndex(0);
              }}
            >
              See all photos
            </button>
          </div>

          {isOpen && (
            <LightBox
              enableZoom={false}
              mainSrc={imageOptimizer(gallery[photoIndex], 1600)}
              nextSrc={gallery[(photoIndex + 1) % gallery.length]}
              prevSrc={
                gallery[(photoIndex + gallery.length - 1) % gallery.length]
              }
              onCloseRequest={() => setIsOpen(false)}
              onMovePrevRequest={() =>
                setPhotoIndex(
                  (photoIndex + gallery.length - 1) % gallery.length
                )
              }
              onMoveNextRequest={() =>
                setPhotoIndex((photoIndex + 1) % gallery.length)
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Gallery;
