import React from "react";
import * as s from "./index.module.scss";
import ContactForm from "../index";

const MobileContactForm = ({ location = {}, close = () => null }) => {
  return (
    <div className={s.wrapper}>
      <div className={s.header}>
        <div className="container outer">
          <div className="text-end">
            <button onClick={close} className={"px-0 btn"}>
              <img src={require("./close.svg").default} alt="Close" />
            </button>
          </div>
        </div>
      </div>
      <div className="container outer">
        <ContactForm location={location} close={close} />
      </div>
    </div>
  );
};

export default MobileContactForm;
